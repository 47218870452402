<template>
    <div>
        <v-row no-gutters>
            <v-col cols="12" md="6" style="margin: auto;">
                <v-row align="center" justify="center">
                    <v-card width="450" flat tile class="pa-6 mt-10 mx-auto">
                        <v-card flat class="d-flex justify-center">
                            <div>
                                <!-- <v-img
                                    width="130"
                                    src="../assets/img/logo.jpg"
                                /> -->

                                <v-img
                                    src="../assets/img/logo2.png"
                                    class="ma-auto"
                                    width="100"
                                />
                                <p class="text-center blue--text">
                                    مركز الكفيل لتقنية المعلومات
                                </p>
                            </div>
                        </v-card>

                        <v-card-title>
                            <h4>تسجيل دخول</h4>
                        </v-card-title>
                        <v-card-text class="mt-n3">
                            مرحبًا بك في نظام
                            <strong> إدارة الفنادق </strong>
                            من مركز الكفيل لتقنية المعلومات
                        </v-card-text>

                        <v-form
                            @submit.prevent="handleLogin"
                            ref="form"
                            v-model="valid"
                            lazy-validation
                        >
                            <v-card-actions>
                                <v-text-field
                                    v-model="user.username"
                                    placeholder="إسم المستخدم"
                                    outlined
                                    color="third"
                                    :rules="required"
                                >
                                </v-text-field>
                            </v-card-actions>
                            <v-card-actions class="mt-n4">
                                <v-text-field
                                    v-model="user.password"
                                    placeholder="كلمة المرور"
                                    outlined
                                    color="third"
                                    :rules="required"
                                    :append-icon="
                                        showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                    "
                                    :type="showPassword ? 'text' : 'password'"
                                    @click:append="showPassword = !showPassword"
                                >
                                </v-text-field>
                            </v-card-actions>
                            <v-card-actions>
                                <v-btn
                                    :loading="loading"
                                    type="submit"
                                    dark
                                    block
                                    large
                                    color="blue"
                                >
                                    تسجيل الدخول
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>

        <!-- <v-footer class="footer" padless>
            <v-row justify="center" class="grey--text">
                <small>
                    © معةد الكفيل لتطوير المةارات
                    {{ new Date().getFullYear() }}
                </small>
            </v-row>
        </v-footer> -->
    </div>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            user: {},
            loading: false,
            valid: true,
            showPassword: false,
            required: [v => !!v || "ةذا الحقل مطلوب"]
        };
    },
    // computed: {
    //     loggedIn() {
    //         return this.$store.state.auth.status.loggedIn;
    //     }
    // },
    // created() {
    //     // if (this.loggedIn) {
    //     //     this.$router.push("/");
    //     // }
    // },

    methods: {
        handleLogin() {
            this.$refs.form.validate();
            this.user.clientType = "Web";
            this.user.clientId = "2d9e6c79-eb9f-42f0-85be-05f643d95e6c";
            if (this.user.username && this.user.password) {
                this.loading = true;
                this.$http
                    .post("account/login", this.user)
                    .then(res => {
                        this.$store.dispatch("setSnackbar", {
                            text: `تم تسجيل الدخول بنجاح`
                        });
                        localStorage.setItem(
                            "token",
                            res.data.data.accessToken
                        );
                        localStorage.setItem(
                            "user",
                            JSON.stringify(res.data.data)
                        );
                        this.$store.dispatch("auth/login");
                        this.$store.dispatch("roles/setRoles");
                        this.$router.push("/");
                        console.log("login");
                    })
                    .catch(err => {
                        this.$store.dispatch("setSnackbar", {
                            text: `إسم المستخدم او كلمة المرور خطأ`,
                            color: "error"
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.$store.dispatch("setSnackbar", {
                    text: `الرجاء ادخل اسم المستخدم او كلمة المرور `,
                    color: "error"
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.footer {
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
}

.footer-link {
    padding: 0 8px;
    color: grey;
    text-decoration-line: none;
    font-size: 12px;
}

.footer-link:hover {
    text-decoration-line: underline;
}
</style>
